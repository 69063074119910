import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledDrawer = styled.nav`
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 10px;
      border: none;
      margin: 0;
      width: auto;
      overflow: visible;
      background: transparent;

      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;

      line-height: normal;
    }
  }
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-out;
  overflow: scroll;
  ul {
    display: block !important;
    min-height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin: 0;
    a {
      padding: 20px;
      width: 100%;
      display: block;
      text-decoration: none;
      text-transform: capitalize;
      &.active {
        border-left: solid 6px #01600f;
        background-color: #ececec;
        &:hover {
          color: #003300;
        }
      }
    }
  }
`;

function sideDrawer(props) {
  return (
    <StyledDrawer {...props}>
      <div className="buttonContainer">
        <button type="button" onClick={props.handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40px">
            <path
              fill="#2e7d32"
              d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"
            />
          </svg>
        </button>
      </div>
      <nav id="sideDrawer">
        <ul>
          <li>
            <Link activeClassName="active" to="/ploegen">
              ploegen
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/contact">
              Contact
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/aansluiten">
              Aansluiten
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/documenten">
              Documenten
            </Link>
          </li>

          <li>
            <a href="http://oxacobbcvrienden.be" target="_blank" rel="noopener noreferrer">
              Sportpaleis
            </a>
          </li>
          {/* <li>
            <a
              href="https://charles-sportswear.myshopify.com/collections/oxaco"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shop
            </a>
          </li> */}
        </ul>
      </nav>
    </StyledDrawer>
  );
}

export default sideDrawer;
