import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

const Styledsponsors = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  a {
    width: 140px;
    padding: 10px;
    @media (max-width: 576px) {
      width: 75px;
      margin: 0 10%;
    }
  }
`;

class Sponsors extends Component {
  render() {
    return (
      <Styledsponsors>
        <StaticQuery
          query={graphql`
            query {
              allContentfulSponsor {
                edges {
                  node {
                    naam
                    logo {
                      file {
                        contentType
                        url
                      }
                      fluid(maxWidth: 125) {
                        ...GatsbyContentfulFluid
                      }
                    }
                    website
                    contentful_id
                  }
                }
              }
            }
          `}
          render={data =>
            data.allContentfulSponsor.edges.map(sponsor => {
              const { naam, website, contentful_id, logo } = sponsor.node;
              return (
                <a
                  href={website}
                  aria-label={`sponsor ${naam}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={contentful_id}
                >
                  {logo.file.contentType.includes('svg') ? (
                    <img src={logo.file.url} alt="" />
                  ) : (
                    <Image fluid={logo.fluid} />
                  )}
                </a>
              );
            })
          }
        />
      </Styledsponsors>
    );
  }
}

export default Sponsors;
