import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Sponsors from '../Sponsors';
import logo from '../../images/oxaco-logos/oxaco-logo.svg';
import Container from './Container';

const StyledFooter = styled.footer`
  position: relative;
  width: 100%;
  overflow: hidden;
  #footerLogoContainer {
    background-color: #f5f5f6;
    #footerLogo {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      justify-content: center;
      /* color: #356429; */
      img {
        height: 100px;
        padding: 20px;
        width: auto;
        display: block;
        max-width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .footerLinksContainer {
    background-color: #e1e2e1;
    padding: 20px 0;
    .footerLinks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      ul {
        margin-left: 0;
        li {
          list-style-type: none;
          margin-top: 10px;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
`;

const footer = () => (
  <StyledFooter>
    <Container>
      <Sponsors />
    </Container>
    <div id="footerLogoContainer">
      <div id="footerLogo">
        <Link to="/">
          <img src={logo} alt="logo Oxaco" />
        </Link>
      </div>
    </div>
    <div className="footerLinksContainer">
      <Container>
        <div className="footerLinks">
          <div>
            <h4>Club</h4>
            <ul>
              <li>
                <Link activeClassName="active" to="/documenten">
                  Documenten
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/contact">
                  Contact
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/aansluiten">
                  Aansluiten
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/doelstellingen">
                  Doelstellingen
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>Extra</h4>
            <ul>
              <li>
                <Link activeClassName="active" to="/fotos">
                  Foto's
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/wedstrijdverslagen">
                  Wedstrijdverslagen
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/vblkalender" className="hideMobile">
                  Vbl kalender
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h4>Externe links</h4>
            <ul>
              <li>
                <a href="https://fcoxaco-boechout.be" target="_blank" rel="noopener noreferrer">
                  FC Oxaco - Boechout
                </a>
              </li>
              <li>
                <a href="http://www.oxaco.be" target="_blank" rel="noopener noreferrer">
                  Oxaco volleybal
                </a>
              </li>
              <li>
                <a href="http://www.oxaco-tennis.be/" target="_blank" rel="noopener noreferrer">
                  Oxaco tennis
                </a>
              </li>

              <li>
                <a href="https://www.capenberg.com/" target="_blank" rel="noopener noreferrer">
                  Capenberg
                </a>
              </li>
              {/* <li>
                <a href="www.oxaco-bewegingsschool.be" target="_blank" rel="noopener noreferrer">
                  Oxaco bewegingsschool
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  </StyledFooter>
);

export default footer;
