import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { relative } from 'path';
import SideDrawer from './SideDrawer';
import Backdrop from './Backdrop';

import Logo from '../logo';
import Container from '../layout/Container';
import NavLinks from './NavLinks';

const StyledNav = styled.nav`
  top: ${({ skewed }) => (skewed ? '-10px' : 0)};
  position: ${({ absolute }) => (absolute ? 'absolute' : 'fixed')};
  margin-top: ${({ absolute }) => (absolute ? '10px' : '0px')};
  box-shadow: ${({ skewed, absolute }) => (skewed && !absolute ? '0 1px 10px #999' : 'none')};
  border-top: ${({ absolute }) => (absolute ? 'none' : 'solid 10px #01600F')};
  background-color: ${({ absolute }) => (absolute ? 'transparent' : '#fff')};
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  #linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    #navLogoContainer {
      width: 200px;
      justify-content: center;
    }
    #navLogo {
      transition: height 0.2s;
      width: auto;
      z-index: 2;
      margin: 10px;
    }
    #logoLink {
      position: relative;
    }
    #logoLink.skewbox::before {
      content: ' ';
      display: block;
      position: absolute;
      background-color: #388e3c;
      transform: skewX(-30deg);
      z-index: -1;
      top: 0;
      bottom: 0;
      left: -1000px;
      right: -40px;
    }
    > ul > li > a {
      display: block;
      padding: 20px;
      color: ${({ absolute }) => (absolute ? '#fff' : '#00600F')};
      text-decoration: none;
      text-transform: capitalize;
    }
  }
  button {
    background: none;
    border: none;
    padding: 0;
    @media (min-width: 769px) {
      display: none;
    }
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 24px;
      width: 30px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      box-sizing: border-box;
      &:focus {
        outline: none;
      }
      span.line {
        width: 30px;
        height: 2px;
        background: #fff;
        background: ${({ absolute }) => (absolute ? '#fff' : '#2e7d32')};
      }
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 40px;
    /* a.active {
      border-bottom: 2px solid green;
    } */
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.checkForMobile = this.checkForMobile.bind(this);
    this.hamburgerClick = this.hamburgerClick.bind(this);
    this.state = {
      skewed: false,
      sideDrawerOpen: false,
      mobile: false,
    };
  }

  componentDidMount() {
    this.checkForMobile(window.innerWidth);
    window.addEventListener('resize', this.checkForMobile);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkForMobile);
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleBackdrop = () => this.setState({ sideDrawerOpen: false });

  handleScroll = event => {
    if (event.currentTarget.scrollY > 20) {
      this.setState({ skewed: true });
    } else {
      this.setState({ skewed: false });
    }
  };

  checkForMobile(e) {
    const windowWidth = e.target ? e.target.innerWidth : e;
    if (windowWidth <= 768) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }
  }

  hamburgerClick() {
    this.setState(prevState => ({ sideDrawerOpen: !prevState.sideDrawerOpen }));
  }

  render() {
    const { skewed, sideDrawerOpen, mobile } = this.state;
    const { home } = this.props;

    return (
      <>
        {sideDrawerOpen && <Backdrop clickHandler={() => this.toggleBackdrop()} />}
        {mobile && <SideDrawer open={sideDrawerOpen} handleClose={this.hamburgerClick} />}
        <StyledNav mobile={mobile} skewed={skewed} absolute={home}>
          <Container>
            <div id="linkContainer">
              <Link
                to="/"
                aria-label="home"
                id="logoLink"
                className={skewed && !home ? 'skewbox' : ''}
              >
                <Logo
                  id="navLogo"
                  color={skewed || home ? 'white' : 'green'}
                  style={{
                    height: skewed && !home ? '35px' : '60px',
                    position: relative,
                  }}
                />
              </Link>
              <NavLinks absolute={home} />

              <button
                aria-label="Menu"
                id="hamburgerButton"
                onClick={this.hamburgerClick}
                type="button"
              >
                <div className="hamburger">
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
              </button>
            </div>
          </Container>
        </StyledNav>
        <div style={{ height: home ? '0px' : '120px' }} />
      </>
    );
  }
}

export default Navbar;
