import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Nav from '../components/navigation/nav';

import LuwebCredential from '../components/LuwebCredential';

import logo from '../images/oxaco-logos/logo.png';
import Footer from '../components/layout/footer';
// import VblData from "../components/vblData";

import '../stylesheets/layout.css';
import '../stylesheets/custom.sass';

class defaultLayout extends Component {
  render() {
    const { children, home } = this.props;
    return (
      <>
        <Helmet
          htmlAttributes={{ lang: 'nl-BE' }}
          title="Oxaco BBC"
          meta={[
            { name: 'description', content: 'Oxaco BBC, basketbalclub' },
            { name: 'keywords', content: 'basketbal, sport, Antwerpen' },
            { name: 'theme-color', content: '#356429' },
          ]}
          link={[{ rel: 'shortcut icon', type: 'image/png', href: `${logo}` }]}
        />
        <Nav home={home} />
        {/* <div style={{ marginTop: home ? '0px' : '120px' }}>{children}</div> */}
        <div style={{ marginTop: '0px', minHeight: '38vh' }}>{children}</div>
        <Footer />

        <LuwebCredential />
      </>
    );
  }
}

export default defaultLayout;
