import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';

const StyledNavLinks = styled.ul`
  li.dropdown {
    color: ${({ absolute }) => (absolute ? '#fff' : '#2e7d32')};
    padding: 20px;
    position: relative;
    margin-right: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    > ul {
      background-color: ${({ absolute }) => (absolute ? '#fff' : '#2e7d32')};
      display: none;
      position: absolute;
      top: 50px;
      left: -50%;
      margin-left: 0;
      margin-right: 0;
      border-radius: 10px;
      /* color: ${({ theme }) => theme.primary}; */
      li {
        background-color: ${({ absolute }) => (absolute ? '#fff' : '#2e7d32')};;
        &:first-child {
          border-radius: 5px 5px 0 0;
          a {
          border-radius: 5px 5px 0 0;
          }
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
          a {
          border-radius: 0 0 5px 5px;
          }
        }
        a {
          display: block;
          padding: 20px 40px;
          text-decoration: none;
          text-transform: capitalize;
          color: #333;
          color: ${({ absolute }) => (absolute ? '#2e7d32' : '#fff')};
          &:hover {
            background-color: #4b844a;
            color: #fff;
          }
        }
        border-radius: 10px;
      }
      &:hover {
        display: block;
      }
    }
    &:hover > ul {
      display: block;
    }
    #teams {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      width: 200%;
      background-color: white;
      &:hover {
        #teams {
          display: flex;
        }
      }
    }
  }
`;

const navLinks = ({ absolute }) => (
  <StyledNavLinks absolute={absolute}>
    <li>
      <Link activeClassName="active" to="/ploegen">
        ploegen
      </Link>
    </li>
    <li className="dropdown">
      Club
      <ul>
        <li>
          <Link activeClassName="active" to="/documenten">
            Documenten
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/doelstellingen">
            Doelstellingen
          </Link>
        </li>

        <li>
          <Link activeClassName="active" to="/contact">
            Contact
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/aansluiten">
            Aansluiten
          </Link>
        </li>
      </ul>
    </li>

    <li>
      <a href="http://oxacobbcvrienden.be" target="_blank" rel="noopener noreferrer">
        Sportpaleis
      </a>
    </li>
    {/* <li>
      <a
        href="https://charles-sportswear.myshopify.com/collections/oxaco"
        target="_blank"
        rel="noopener noreferrer"
      >
        Shop
      </a>
    </li> */}
  </StyledNavLinks>
);

export default navLinks;
