import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 80vw;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const Container = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
